<template>
  <div class="row mx-0 justify-content-center">
    <!-- <PaymentGateway v-if="sadaqahWizard.pageDetails_2.paymentMethod === 'card' && sadaqahWizard.pageDetails_3.isShowGateway" /> -->
    <ReviewDonation v-model="value" v-model:terms="termsValue" @goToDonorPage="$emit('goToDonorPage')" @goToPaymentPage="$emit('goToPaymentPage')" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'Wizard 3',
  components: {
    // PaymentGateway: defineAsyncComponent(() => import('./views/paymentgateway/PaymentGateway.vue')),
    ReviewDonation: defineAsyncComponent(() => import('./views/reviewdonation/ReviewDonation.vue'))
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    terms: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue', 'update:terms', 'goToDonorPage', 'goToPaymentPage'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    termsValue: {
      get () {
        return this.terms
      },
      set (value) {
        this.$emit('update:terms', value)
      }
    }
  }
}
</script>
